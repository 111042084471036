import rpc from "@/rpc";

class ReportService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    continueReport(reportId) {
        return this.rpc.execute('continueReport', {
            reportId: +reportId
        })
    }

    resetReport(reportId) {
        return this.rpc.execute('resetReport', {
            reportId: +reportId
        })
    }

    createReportAction(type, reportId) {
        return this.rpc.execute('createReportAction', {
            type: type,
            reportId: +reportId,
        })
    }

    createOkbReport() {
        return this.rpc.execute('createOkbReport')
    }
}

export default new ReportService(rpc)