<template>
  <div>
    <div v-if="loading">
      <div class="flex col-12 justify-content-center" style="position: absolute;top: 50%;transform: translate(0, -50%);">
        <ProgressSpinner></ProgressSpinner>
      </div>
    </div>
    <div class="grid" v-if="!loading && !error">
      <div class="col-12">
        <Card>
          <template #title>Отчёт №{{ report.id }} </template>
        </Card>
      </div>
      <div class="col-12 lg:col-6">
        <Card>
          <template #title>Основная информация</template>
          <template #content>
            <p><strong>Дата создания: </strong>{{ dateService.dateToString(report.createdAt) }}</p>
            <p v-if="report.updatedAt !== null"><strong>Дата обновления: </strong>{{ dateService.dateToString(report.updatedAt) }}</p>
            <p v-if="report.completedAt !== null"><strong>Дата завершения: </strong>{{ dateService.dateToString(report.completedAt) }}</p>
            <p><strong>Статус: </strong><StatusChip :value="report.status" compendium="processStatuses"/></p>
            <p><strong>Бюро: </strong>{{ $filters.translate($store, 'reportBureaus', report.bureau) }}</p>
            <p><strong>Имя файла: </strong>{{ report.filename }}</p>
          </template>
          <template #footer>
            <div class="grid justify-content-between">
              <div>
                <SplitButton v-if="report.allowedActions.length > 0 || report.fileId !== null" label="Действия по отчёту" icon="pi pi-bolt" :model="createActions()"></SplitButton>
              </div>
              <div>
                <Button v-if="report !== null" @click="refresh" icon="pi pi-refresh" class="p-button-rounded p-button-outlined"></Button>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-12 lg:col-6">
        <Card>
          <template #title>История</template>
          <template #content>
            <TabView>
              <TabPanel header="Статусы">
                <AbstractTable
                    code="reportStatuses"
                    :default-data="{reportId: report.id}"
                    display-filter="menu"
                    hide-header
                >
                  <template #status="row">
                    <StatusChip
                      :value="row.data.status"
                      compendium="processStatuses"
                    />
                  </template>
                </AbstractTable>
              </TabPanel>
              <TabPanel header="Действия">
                <AbstractTable
                  code="reportActions"
                  :default-data="{reportId: report.id}"
                  display-filter="menu"
                  hide-header
                >
                  <template #status="row">
                    {{ $filters.translate($store, 'reportActionStatus', row.data.status) }}
                  </template>
                  <template #type="row">
                    {{ $filters.translate($store, 'reportActionType', row.data.type) }}
                  </template>
                </AbstractTable>
              </TabPanel>
              <TabPanel header="Файлы ответа">
                <AbstractTable
                  code="reportResponses"
                  :default-data="{reportId: report.id}"
                  display-filter="menu"
                  hide-header
                >
                  <template #actions="row">
                    <Button icon="pi pi-fw pi-download" label="Скачать" @click="downloadReportResponse(row.data.id)"></Button>
                  </template>
                </AbstractTable>
              </TabPanel>
            </TabView>
          </template>
        </Card>
      </div>
      <div class="col-12">
        <EventsTable :default-data="{reportId: report.id}"></EventsTable>
      </div>
      <div class="col-12">
        <Card>
          <template #title>Ошибки обработки</template>
          <template #content>
            <AbstractTable code="reportErrors" :default-data="{reportId: report.id}" display-filter="menu" hide-header>
              <template #type="row">
                {{ $filters.translate($store, 'reportErrorType', row.data.type) }}
              </template>
            </AbstractTable>
          </template>
        </Card>
      </div>
    </div>
    <div v-if="!loading && error">
      <p>Произошла ошибка</p>
    </div>
  </div>
</template>

<script>
import CRUDService from "@/service/CRUDService";
import ResponseService from "@/service/ResponseService";
import FilterDateService from "@/service/FilterDateService";
import AbstractTable from "@/components/AbstractTable";
import EventsTable from "@/components/tables/EventsTable";
import StatusChip from "@/components/Common/StatusChip";
import ReportService from "@/service/ReportService";

export default {
  name: "Report",
  components: {StatusChip, EventsTable, AbstractTable},
  data() {
    return {
      actionLoading: false,
      loading: true,
      error: false,
      report: {},
      dateService: FilterDateService,
    }
  },
  methods: {
    refresh() {
      if (this.report !== null) {
        this.loading = true
        this.getReport(this.report.id)
      }
    },
    createActions() {
      let items = []
      if (this.report.fileId !== null) {
        items.push({
          label: 'Скачать файл отчёта',
          icon: 'pi pi-download',
          command: () => {
            this.downloadReport()
          }
        })
      }
      for (let a of this.report.allowedActions) {
        items.push({
          label: this.$filters.translate(this.$store, 'reportActionType', a),
          icon: 'pi pi-bolt',
          command: () => {
            this.runReportAction(a)
          }
        })
      }
      return items
    },
    runReportAction(action) {
      this.loading = true
      ReportService.createReportAction(action, this.report.id).then((res) => {
        this.$toast.add({
          severity: 'success',
          summary: 'Действие постевлено в очередь',
          detail: res.feedback,
          life: 3000
        })
        this.getReport(this.report.id)
      }).catch((err) => {
        ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
        this.loading = false
      })
    },
    downloadReportResponse(id) {
      window.open('/download/report_response_file/' + id, '_blank')
    },
    downloadReport() {
      window.open('/download/report/' + this.report.id, '_blank')
    },
    getReport(id) {
      CRUDService.get('report', id).then((res) => {
        if (Array.isArray(res) && res.length === 0) {
          throw new DOMException('Отчёт не найден')
        }
        this.report = res
        this.loading = false
        this.actionLoading = false
      }).catch((err) => {
        ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
        this.error = true
        this.loading = false
      })
    }
  },
  mounted() {
    let id = this.$router.currentRoute.value.params.id
    if (id && parseInt(id) > 0) {
      this.getReport(id)
    }
  }
}
</script>

<style scoped>

</style>