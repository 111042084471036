export default new class FilterDateService {
    filterDateFrom(str) {
        return str.match(/[0-9][0-9].[0-9][0-9].[0-9][0-9][0-9][0-9]/)[0]
    }

    filterHourAndMinutesFrom(str) {
        return str.match(/[0-9][0-9]:[0-9][0-9]/)[0]
    }

    dateToStr(date) {
        if (date instanceof Date) {
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            month = month < 10 ? '0' + month : month + ''
            let day = date.getDate()
            day = day < 10 ? '0' + day : day + ''
            return year + '-' + month + '-' + day
        }
        return null
    }

    dateToDateWithTimezone(date) {
        console.log(date)
        return date
    }

    dateToString(date) {
        let ts = Date.parse(date)
        let d = new Date()
        d.setTime(ts)
        return d.toLocaleString('ru-RU', {
            timeZone: 'Europe/Moscow'
        }).replace(',', '')
    }
}